import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import TestimonialsStyleOne from "../components/Testimonials/TestimonialsStyleOne"
import TestimonialsStyleTwo from "../components/Testimonials/TestimonialsStyleTwo"

const Testimonios = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Testimonios"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Testimonios"
      />
      <TestimonialsStyleOne />
      <TestimonialsStyleTwo />
      <Footer />
    </Layout>
  )
}

export default Testimonios
